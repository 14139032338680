<!-- Modal to display promotions that are included on a batch billing run. This modal is accessed from the actions menu on BatchBilling.vue -->
<template>
    <v-dialog justify="center" :value="value" persistent>
        <v-container class="ma-0 pa-0" fluid>
        <v-card color="fill">
            <v-row>
                <v-col cols="11">
                    <v-card-title>Promotions on Batch: {{selectedBatch.name}}</v-card-title>
                </v-col>
                <v-spacer />
                <v-col>
                    <v-btn class="mt-2" text @click="$emit('update:value', false)" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider />
            <v-card ma-0 pa-0 fluid>
            <v-data-table
            :headers="headers"
            :height="tableHeight"
            :items="promos"
            :items-per-page="pageSize"
            no-data-text="No Data"
            loading-text="Loading..."
            :loading="loading"
            dense
            fixed-header
            hide-default-footer>

            <template #[`item.enforce_compliance`]="{ item }">
                <span v-if="item.enforce_compliance">
                    <v-icon color="green">mdi-checkbox-marked-circle-outline</v-icon>
                </span>
                <span v-else>
                    <v-icon color="red">mdi-close-circle-outline</v-icon>
                </span>
            </template>

            <template #[`item.promo_number`]="{ item }">
                <span v-if="item.promo_number"> {{item.promo_number}}</span>
                <span v-else> N/A </span>
            </template>

            <template #[`item.info`]="{item}">
                <v-tooltip top>
                    <template #activator="{on}">
                        <v-btn v-on="on" icon @click.stop="copyID(item)">
                            <v-icon>mdi-information</v-icon>
                         </v-btn>
                    </template>
                        <span>{{item.promo_id}}</span>
                </v-tooltip>
            </template>

            </v-data-table>
            </v-card>

            <v-toolbar dense flat clolor="#fafafa">
                <v-row align="center" justify="end">
                    <v-col cols="2" style="max-width:150px;">
                        <v-select class="mt-8" dense :items="pageSizes" label="Items Per Page" select v-model="pageSize"
                        :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
                        </v-select>
                    </v-col>
                        <v-btn icon large @click="previous()" :disabled="disablePrevious" class="mx-2">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <small>Page {{page}}</small>
                        <v-btn icon large @click="next()" :disabled="disableNext" class="mx-2">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                </v-row>
            </v-toolbar>
        </v-card>
        </v-container>
    </v-dialog>
</template>
<script>
    import BillingBatch from '@/axios/billing-batch.js'
    import { format } from '@/mixins/format'
    import { displayAlert } from '@/mixins/displayAlert'
    export default {
        name: 'PromotionTable',
        mixins: [format, displayAlert],
        props: [
            'value',
            'selectedBatch'
        ],
        data () {
            return {
                promos: [],
                page: 1,
                pageSize: 50,
                pageSizes: [50, 100],
                maxWidth: 1000,
            }
        },
        computed: {
            offset () {
                return (this.page - 1) * this.pageSize
            },
            disablePrevious () {
                return this.offset === 0
            },
            disableNext () {
                return this.promos.length < this.pageSize
            },
            headers () {
                return [
                    { sortable: false, class: 'accent white--text', width: '3%'},
                    { text: 'Promotion Name', align: 'left', sortable: false, filterable: true, value: 'promo_name', class: 'accent white--text' },
                    { text: 'Promotion Number', align: 'left', sortable: false, filterable: true, value: 'promo_number', class: 'accent white--text' },
                    { text: 'Start Date', align: 'left', sortable: false, filterable: true, value: 'promo_start_dt', class: 'accent white--text' },
                    { text: 'End Date', align: 'left', sortable: false, filterable: true, value: 'promo_end_dt', class: 'accent white--text' },
                    { text: 'Enforce Compliance', align: 'left', sortable: false, filterable: true, value: 'enforce_compliance', class: 'accent white--text' },
                    { text: 'Promotion Type', align: 'left', sortable: false, filterable: true, value: 'billing_mode', class: 'accent white--text' },
                    { text: 'Promotion Category', align: 'left', sortable: false, filterable: true, value: 'promo_category_name', class: 'accent white--text' },
                    { text: 'Classification', align: 'left', sortable: false, filterable: true, value: 'classification', class: 'accent white--text' },
                    { text: 'Promotion ID', align: 'left', sortable: false, filterable: true, value: 'info', class: 'accent white--text' }
                ]
            }
        },
        created () {
            this.tableHeight = window.innerHeight - 300
            this.maxWidth = window.innerWidth - 500
        },
        watch: {
            value: {
                immediate: true,
                handler () {
                    if (this.value) this.getPromos()
                }
            }
        },
        methods: {
        async getPromos () {
            this.loading = true
            try {
                const limit = this.pageSize
                const offset = this.offset
                const res = await BillingBatch.getBatchPromos(this.selectedBatch.id, limit, offset)
                if (res.data) {
                    this.promos = res.data
                }
            } catch (err) {
                this.handleError(err)
            } finally {
                this.loading = false
            }
        },
        getCompliance (compliance) {
            if (compliance) {
                return "mdi-check"
            }
            else {
                return "mdi-close"
            }
        },

        previous () {
            this.page = this.page - 1
            if (this.page < 1) {
                this.page = 1
            }
            this.getPromos()
         },

        next () {
            this.page = this.page + 1
            this.getPromos()
        },

        copyID(item) {
            navigator.clipboard.writeText(item.promo_id)
            this.$store.dispatch('setSnackbar', { status: 'success', text: `Copied!` })
        },


        }

    }
</script>
